import { useEffect, useMemo, useState } from "react";
import { format, startOfDay, endOfDay } from "date-fns";
import Select from "react-select";
import { EChartsOption } from "echarts-for-react";
import ReactECharts from "echarts-for-react";
import prettyBytes from "pretty-bytes"; // <-- We use this for human-readable formatting
import { MonitoringDataDto } from "api/monitoring-data/dto/monitoring-data.dto";
import { getMonitoringData } from "api/monitoring-data/get-monitoring-data";
import { Sensor } from "models/sensor.model";

interface TrafficChartProps {
  sensor: Sensor;
  startDate: Date;
  endDate: Date;
}

export function TrafficChart({
  sensor,
  startDate,
  endDate,
}: TrafficChartProps) {
  // Raw traffic data from backend
  const [trafficData, setTrafficData] = useState<MonitoringDataDto[] | null>(
    null
  );

  // All interface names found in the data
  const [allInterfaces, setAllInterfaces] = useState<string[]>([]);

  // Which interfaces are selected?
  const [selectedInterfaces, setSelectedInterfaces] = useState<string[]>([]);

  // 1) Fetch traffic data
  useEffect(() => {
    (async () => {
      try {
        const response = await getMonitoringData(
          sensor._id,
          startOfDay(startDate).toISOString(),
          endOfDay(endDate).toISOString(),
          "traffic"
        );
        if (!response.ok) {
          console.error("Error fetching traffic data.");
          return;
        }

        const data: MonitoringDataDto[] = await response.json();
        setTrafficData(data);

        // Gather all unique interface names
        const interfaceSet = new Set<string>();
        for (const doc of data) {
          for (const netIf of doc.networkInterfaces || []) {
            // Remove extra quotes if any
            const cleanedName = netIf.name.replace(/"/g, "");
            interfaceSet.add(cleanedName);
          }
        }
        const allIfList = Array.from(interfaceSet);

        // Default selection if user hasn't selected anything
        if (selectedInterfaces.length === 0) {
          const sorted = sortInterfaces(allIfList);
          setSelectedInterfaces(sorted.slice(0, 5)); // pick up to 5
        }

        setAllInterfaces(allIfList);
      } catch (error) {
        console.error("Error fetching/parsing traffic data:", error);
      }
    })();
  }, [sensor._id, startDate, endDate]);

  // 2) Build separate ECharts options for inbound (“entrada”) and outbound (“salida”)
  const [inboundOption, outboundOption] = useMemo(() => {
    if (!trafficData) return [null, null];

    // Build two maps:
    // inboundMap[ifName] => array of [timeStr, bytesIn]
    // outboundMap[ifName] => array of [timeStr, bytesOut]
    const inboundMap: Record<string, [string, number][]> = {};
    const outboundMap: Record<string, [string, number][]> = {};

    for (const doc of trafficData) {
      const timeStr = format(new Date(doc.date), "yyyy-MM-dd HH:mm");

      for (const netIf of doc.networkInterfaces || []) {
        const cleanedName = netIf.name.replace(/"/g, "");
        if (!selectedInterfaces.includes(cleanedName)) {
          continue;
        }

        if (!inboundMap[cleanedName]) inboundMap[cleanedName] = [];
        if (!outboundMap[cleanedName]) outboundMap[cleanedName] = [];

        inboundMap[cleanedName].push([timeStr, netIf.bytesIn]);
        outboundMap[cleanedName].push([timeStr, netIf.bytesOut]);
      }
    }

    // Convert inbound map -> ECharts series
    const inboundSeries = Object.keys(inboundMap).map((ifName) => ({
      name: `${ifName} entrada`,
      type: "line" as const,
      showSymbol: false,
      data: inboundMap[ifName],
    }));

    // Convert outbound map -> ECharts series
    const outboundSeries = Object.keys(outboundMap).map((ifName) => ({
      name: `${ifName} salida`,
      type: "line" as const,
      showSymbol: false,
      data: outboundMap[ifName],
    }));

    // If no inbound or no outbound data, skip building the chart
    const inboundOpt: EChartsOption | null =
      inboundSeries.length > 0
        ? {
            title: {
              text: "Tráfico de entrada",
            },
            tooltip: {
              trigger: "axis",
              // Show human-readable bytes in tooltip
              formatter: (params: any) => {
                // params is an array of data points
                const date = params[0]?.name || "";
                let tooltipText = `${date}<br/>`;
                for (const p of params) {
                  const bytesVal = p.value[1]; // [timeStr, number]
                  tooltipText += `${p.marker} ${p.seriesName}: ${prettyBytes(
                    bytesVal
                  )}<br/>`;
                }
                return tooltipText;
              },
            },
            legend: {
              data: inboundSeries.map((s) => s.name),
            },
            xAxis: {
              type: "category",
            },
            yAxis: {
              type: "value",
              axisLabel: {
                formatter: (value: number) => prettyBytes(value),
              },
            },
            series: inboundSeries,
          }
        : null;

    const outboundOpt: EChartsOption | null =
      outboundSeries.length > 0
        ? {
            title: {
              text: "Tráfico de salida",
            },
            tooltip: {
              trigger: "axis",
              formatter: (params: any) => {
                const date = params[0]?.name || "";
                let tooltipText = `${date}<br/>`;
                for (const p of params) {
                  const bytesVal = p.value[1];
                  tooltipText += `${p.marker} ${p.seriesName}: ${prettyBytes(
                    bytesVal
                  )}<br/>`;
                }
                return tooltipText;
              },
            },
            legend: {
              data: outboundSeries.map((s) => s.name),
            },
            xAxis: {
              type: "category",
            },
            yAxis: {
              type: "value",
              axisLabel: {
                formatter: (value: number) => prettyBytes(value),
              },
            },
            series: outboundSeries,
          }
        : null;

    return [inboundOpt, outboundOpt];
  }, [trafficData, selectedInterfaces]);

  // 3) Build react-select options
  const interfaceOptions = useMemo(
    () =>
      allInterfaces.map((ifName) => ({
        value: ifName,
        label: ifName,
      })),
    [allInterfaces]
  );

  const selectedValues = useMemo(
    () =>
      interfaceOptions.filter((opt) => selectedInterfaces.includes(opt.value)),
    [interfaceOptions, selectedInterfaces]
  );

  function handleInterfaceChange(
    newSelected: { value: string; label: string }[] | null
  ) {
    if (!newSelected) {
      setSelectedInterfaces([]);
      return;
    }
    setSelectedInterfaces(newSelected.map((opt) => opt.value));
  }

  return (
    <div>
      {/* Multi-select to pick which interfaces to chart */}
      <h3 className="text-xl font-black">Tráfico</h3>
      <div style={{ marginBottom: "1rem" }}>
        <label className="mb-1">
            Interfaces de red
        </label>
        <Select
          isMulti
          options={interfaceOptions}
          value={selectedValues}
          onChange={(selected) =>
            handleInterfaceChange(
              selected as { value: string; label: string }[]
            )
          }
          placeholder="Seleccionar interfaces..."
          className="basic-multi-select shadow rounded-lg border-0"
        />
      </div>

      {/* Inbound Chart */}
      <div style={{ marginBottom: "2rem" }}>
        {inboundOption ? (
          <ReactECharts
            option={inboundOption}
            style={{ height: "400px", width: "100%" }}
          />
        ) : (
          <p>No hay datos de entrada para los interfaces seleccionados.</p>
        )}
      </div>

      {/* Outbound Chart */}
      <div>
        {outboundOption ? (
          <ReactECharts
            option={outboundOption}
            style={{ height: "400px", width: "100%" }}
          />
        ) : (
          <p>No hay datos de salida para los interfaces seleccionados.</p>
        )}
      </div>
    </div>
  );
}

/**
 * Sort interface names so 'eth*' come first, then 'wifi*', then everything else (alphabetically).
 */
function sortInterfaces(ifaces: string[]): string[] {
  // We'll define a rank for each prefix:
  //   - eth => 0 (highest)
  //   - wifi => 1
  //   - anything else => 2
  return [...ifaces].sort((a, b) => {
    const rankA = getInterfaceRank(a);
    const rankB = getInterfaceRank(b);
    if (rankA !== rankB) {
      return rankA - rankB; // lower rank => earlier in the list
    }
    // same rank => alphabetical
    return a.localeCompare(b);
  });
}

function getInterfaceRank(ifName: string) {
  if (ifName.startsWith("eth")) return 0;
  if (ifName.startsWith("wifi")) return 1;
  return 2;
}
