import { Sensor } from "@models/sensor.model";

interface SensorDescriptionProps {
  sensor: Sensor;
}

export default function SensorDescription({ sensor }: SensorDescriptionProps) {
  const parseDescription = (desc: string) => {
    // Replace line breaks with <br/>
    let updatedDesc = desc.replace(/\\n/g, "<br/>");

    // Replace *text* with <strong>text</strong>
    updatedDesc = updatedDesc.replace(/\*([^*]+)\*/g, "<strong>$1</strong>");

    return updatedDesc;
  };

  return (
    <div className="sensor-description">
      {sensor.description && (
        <p
          className="text-sm mb-4"
          dangerouslySetInnerHTML={{
            __html: parseDescription(sensor.description),
          }}
        />
      )}
    </div>
  );
}
