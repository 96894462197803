import { Sensor } from "models/sensor.model";
import SensorDescription from "./sensor-description";

export interface WifiPopupProps {
  sensor: Sensor;
  setSelectedSensor: (sensor: Sensor) => void;
  setIsSidePanelOpen: (isOpen: boolean) => void;
}

export function WifiPopup({
  sensor,
  setSelectedSensor,
  setIsSidePanelOpen,
}: WifiPopupProps) {
  return (
    <div className="min-w-[250px]">
      <h3 className="font-black text-lg mb-4">{sensor.name}</h3>
      <SensorDescription sensor={sensor} />
      {sensor.imageUrl && (
        <img
          src={`${process.env.REACT_APP_API_BASE_URL}/v1/files/${sensor.imageUrl}`}
          alt="Imagen del sensor"
          className="w-full h-auto max-h-[150px] rounded-lg object-cover"
        />
      )}
      <button
        onClick={() => {
          setSelectedSensor(sensor);
          setIsSidePanelOpen(true);
        }}
        className="mt-4 mompox-blue text-white px-4 py-1 rounded w-full"
      >
        Ver datos
      </button>
    </div>
  );
}

export default WifiPopup;