import { ApiResponse } from "@models/api-response";
import { WifiZoneReportDataDto } from "./dto/wifi-zone-report-data.dto";

export async function getWifiZoneReportData(): Promise<
  ApiResponse<WifiZoneReportDataDto[]>
> {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/v1/wifi-zone-report-data`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  if (!response.ok) {
    return [null, false];
  }

  return [await response.json(), true];
}
