import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/home";
import { AuthProvider } from "hooks/use-auth";
import LoginForm from "pages/login";
import RegisterForm from "pages/register";
import MySensors from "pages/sensors/sensors-list";
import Profile from "pages/profile";
import VariablesList from "pages/admin/variables/variables-list";
import { AppConfiguration } from "pages/admin/configuration";
import { IcasList } from "pages/admin/icas/icas-list";
import { ToastContainer } from "react-toastify";
import Report from "pages/sensors/report";
import MonitoringReport from "pages/sensors/monitoring-report";
import WifiReport from "pages/admin/wifi-report/wifi-report";

function App() {
  return (
    <div>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/register" element={<RegisterForm />} />
            <Route path="/my-sensors" element={<MySensors />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/admin/variables" element={<VariablesList />} />
            <Route path="/admin/configuration" element={<AppConfiguration />} />
            <Route path="/admin/icas" element={<IcasList />} />
            <Route path="/admin/wifi-report" element={<WifiReport />} />
            <Route path="/report" element={<Report />} />
            <Route path="/monitoring-report" element={<MonitoringReport />} />
          </Routes>
        </Router>
      </AuthProvider>

      <ToastContainer />
    </div>
  );
}

export default App;
