import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { toast } from "react-toastify";
import { CreateWifiZoneReportDataDto } from "api/wifi-zone-report-data/dto/create-wifi-zone-report-data.dto";
import { getWifiZoneReportData } from "api/wifi-zone-report-data/get-wifi-zone-report-data";

interface WifiReportChartProps {
  data?: CreateWifiZoneReportDataDto[];
}

const WifiReportChart: React.FC<WifiReportChartProps> = ({ data }) => {
  const [reportData, setReportData] = useState<CreateWifiZoneReportDataDto[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Fetch data if not provided via props
  useEffect(() => {
    if (data && data.length > 0) {
      setReportData(data);
    } else {
      // Fetch data from backend
      const getData = async () => {
        setLoading(true);
        try {
          const [fetchedData, ok] = await getWifiZoneReportData();
          if (!ok) {
            throw new Error("Failed to fetch WiFi zone report data.");
          }
          setReportData(fetchedData);
        } catch (err) {
          console.error(err);
          setError("Error fetching data.");
          toast.error("Error fetching WiFi zone report data.");
        } finally {
          setLoading(false);
        }
      };
      getData();
    }
  }, [data]);

  // Process data to get counts
  const processData = () => {
    const genderCounts: { [key: string]: number } = {};
    const nationalityCounts: { [key: string]: number } = {};
    const ageRangeCounts: { [key: string]: number } = {};

    reportData.forEach((entry) => {
      // Gender
      const gender = entry.gender.trim();
      if (gender) {
        genderCounts[gender] = (genderCounts[gender] || 0) + 1;
      }

      // Nationality
      const nationality = entry.nationality.trim();
      if (nationality) {
        nationalityCounts[nationality] =
          (nationalityCounts[nationality] || 0) + 1;
      }

      // Age Range
      const ageRange = entry.ageRange.trim();
      if (ageRange) {
        ageRangeCounts[ageRange] = (ageRangeCounts[ageRange] || 0) + 1;
      }
    });

    return { genderCounts, nationalityCounts, ageRangeCounts };
  };

  const { genderCounts, nationalityCounts, ageRangeCounts } = processData();

  // ECharts Options
  const genderOption = {
    title: {
      text: "Distribución por Género",
      left: "center",
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "left",
      top: "40",
    },
    series: [
      {
        name: "Género",
        type: "pie",
        radius: "50%",
        data: Object.entries(genderCounts).map(([name, value]) => ({
          value,
          name,
        })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  const nationalityOption = {
    title: {
      text: "Distribución por Nacionalidad",
      left: "center",
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "left",
      top: "40",
    },
    series: [
      {
        name: "Nacionalidad",
        type: "pie",
        radius: "50%",
        data: Object.entries(nationalityCounts).map(([name, value]) => ({
          value,
          name,
        })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  const ageRangeOption = {
    title: {
      text: "Distribución por Rango de Edad",
      left: "center",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: {
      type: "category",
      data: Object.keys(ageRangeCounts),
      axisLabel: {
        interval: 0,
        rotate: 30,
      },
    },
    yAxis: {
      type: "value",
      name: "Personas",
    },
    grid: {
      left: 40,
    },
    series: [
      {
        name: "Personas",
        type: "bar",
        data: Object.values(ageRangeCounts),
        itemStyle: {
          color: "#5470C6",
        },
      },
    ],
  };

  if (loading) {
    return <p>Cargando datos del reporte...</p>;
  }

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  if (reportData.length === 0) {
    return <p>No hay datos disponibles para mostrar el reporte.</p>;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
      {/* Gender Distribution Pie Chart */}
      <div className="bg-white shadow rounded p-4">
        <ReactECharts
          option={genderOption}
          style={{ height: "400px", width: "100%" }}
        />
      </div>

      {/* Nationality Distribution Pie Chart */}
      <div className="bg-white shadow rounded p-4">
        <ReactECharts
          option={nationalityOption}
          style={{ height: "400px", width: "100%" }}
        />
      </div>

      {/* Age Range Distribution Bar Chart */}
      <div className="bg-white shadow rounded p-4">
        <ReactECharts
          option={ageRangeOption}
          style={{ height: "400px", width: "100%" }}
        />
      </div>
    </div>
  );
};

export default WifiReportChart;
