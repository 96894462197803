/**
 * This file contains the titles for each route.
 * The title is used in the navbar.
 */
export const ROUTES_WITH_TITLES = [
  // exact match
  { path: "/", title: "", exact: true },
  { path: "/my-sensors", title: "Mis Sensores" },
  { path: "/profile", title: "Mi Perfil" },
  { path: "/admin/configuration", title: "Configuración" },
  { path: "/report", title: "Reporte" },
  { path: "/monitoring-report", title: "Reporte de Monitoreo" },
];
