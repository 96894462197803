export function getMonitoringData(
  sensorId: string,
  startDate: string,
  endDate: string,
  type: string
) {
  const url = `${
    process.env.REACT_APP_API_BASE_URL
  }/v1/monitoring-data/${sensorId}?startDate=${encodeURIComponent(
    startDate
  )}&endDate=${encodeURIComponent(endDate)}&type=${encodeURIComponent(type)}`;

  // Fetch the CSV data as a blob
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
}
