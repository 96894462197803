import { ModalPortal } from "components/modal-portal";
import { useAuth } from "hooks/use-auth";
import { Sensor } from "models/sensor.model";
import { useEffect, useState } from "react";
import SensorDescription from "./sensor-description";

export interface CCTVSensorPopupProps {
  sensor: Sensor;
  setSelectedSensor: (sensor: Sensor) => void;
  setIsSidePanelOpen: (isOpen: boolean) => void;
}

export function CCTVSensorPopup({ sensor }: CCTVSensorPopupProps) {
  const { isAdmin } = useAuth();

  return (
    <div className="min-w-[250px]">
      <h3 className="font-black text-lg mb-4">{sensor.name}</h3>
      <SensorDescription sensor={sensor} />
      {sensor.imageUrl && !isAdmin && (
        <img
          src={`${process.env.REACT_APP_API_BASE_URL}/v1/files/${sensor.imageUrl}`}
          alt="Imagen del sensor"
          className="w-full h-auto max-h-[150px] rounded-lg object-cover"
        />
      )}
      {isAdmin && <CCTVVideoStream sensor={sensor} />}
      {isAdmin && sensor.isMonitoringEnabled && (
        <a href="/monitoring-report">
          <button className="outline outline-2 outline-[#77a1ec] text-[#77a1ec] p-1 rounded-lg font-bold mt-2">
            Reporte monitoreo
          </button>
        </a>
      )}
    </div>
  );
}

interface CCTVVideoStreamProps {
  sensor: Sensor;
}

interface CCTVVideoStreamProps {
  sensor: Sensor;
}

export function CCTVVideoStream({ sensor }: CCTVVideoStreamProps) {
  // This array will contain up to 4 base64 frames (one for each camera).
  // If a camera doesn't exist, we'll just omit it from the array entirely.
  const [frames, setFrames] = useState<(string | null)[]>([]);
  // Track which camera index (if any) is expanded in a modal
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  // Build an array of camera IDs to connect (e.g. "sensorId_1", "sensorId_2", ...)
  const cameraIds: string[] = [];
  if (sensor.rtspUrl) cameraIds.push(`${sensor._id}_1`);
  if (sensor.rtspUrl2) cameraIds.push(`${sensor._id}_2`);
  if (sensor.rtspUrl3) cameraIds.push(`${sensor._id}_3`);
  if (sensor.rtspUrl4) cameraIds.push(`${sensor._id}_4`);

  useEffect(() => {
    // If there are no cameras, do nothing
    if (cameraIds.length === 0) return;

    // Initialize an array of null frames, one slot per camera
    setFrames(Array(cameraIds.length).fill(null));

    // Create one WebSocket per camera
    const sockets = cameraIds.map((cameraId, index) => {
      const ws = new WebSocket("wss://apimompox.bolivar.gov.co");

      ws.onopen = () => {
        console.log("[WS] Connection opened for camera:", cameraId);
        // Request the base64 stream from the server
        ws.send(
          JSON.stringify({
            event: "start_stream",
            cameraId,
            format: "base64",
          })
        );
      };

      ws.onmessage = (event) => {
        // Update only this camera's frame in the array
        const newFrame = event.data; // base64 JPEG from FFmpeg
        setFrames((prevFrames) => {
          const updated = [...prevFrames];
          updated[index] = newFrame;
          return updated;
        });
      };

      ws.onerror = (err) => {
        console.error("[WS] Error in camera:", cameraId, err);
      };

      return ws;
    });

    // On unmount or sensor change, close all websockets
    return () => {
      sockets.forEach((ws) => {
        ws.close();
      });
    };
    // Re-run whenever the sensor._id changes (i.e., new sensor popup)
  }, [sensor._id]);

  if (cameraIds.length === 0) {
    return (
      <div className="rounded-lg shadow p-2">
        <p>No hay cámaras configuradas.</p>
      </div>
    );
  }

  return (
    <div>
      {/* Display all cameras in a 2×2 grid (adjust to your preference) */}
      <div className="grid grid-cols-2 gap-2">
        {cameraIds.map((cameraId, idx) => {
          const frame = frames[idx];
          return (
            <div key={cameraId} className="relative w-full">
              {/* If no frame yet, show a black placeholder */}
              {!frame && (
                <div
                  className="rounded-lg shadow bg-black"
                  style={{ width: "100%", height: "200px" }}
                />
              )}
              {/* Otherwise, show the camera frame */}
              {frame && (
                <>
                  <img
                    src={`data:image/jpeg;base64,${frame}`}
                    alt={`Camera ${idx + 1}`}
                    className="rounded-lg shadow w-full block"
                    style={{ maxHeight: "200px", objectFit: "contain" }}
                  />
                  <button
                    onClick={() => setExpandedIndex(idx)}
                    style={{
                      position: "absolute",
                      bottom: "8px",
                      right: "8px",
                      padding: "6px 12px",
                      background: "rgba(0,0,0,0.6)",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      zIndex: 1000,
                    }}
                  >
                    Expandir
                  </button>
                </>
              )}
            </div>
          );
        })}
      </div>

      {/* Show a modal for whichever camera is expanded */}
      {expandedIndex !== null && (
        <ModalPortal>
          <div
            onClick={() => setExpandedIndex(null)} // click outside to close
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0,0,0,0.7)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 9999,
            }}
          >
            <div
              style={{
                position: "relative",
                maxWidth: "90vw",
                maxHeight: "90vh",
              }}
              onClick={(e) => e.stopPropagation()} // prevent backdrop click from closing
            >
              <img
                src={`data:image/jpeg;base64,${frames[expandedIndex]}`}
                alt={`Expanded Camera ${expandedIndex + 1}`}
                className="rounded-lg shadow"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  display: "block",
                }}
              />
              <button
                onClick={() => setExpandedIndex(null)}
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  padding: "6px 12px",
                  background: "rgba(0,0,0,0.6)",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Cerrar
              </button>
            </div>
          </div>
        </ModalPortal>
      )}
    </div>
  );
}
