import Navbar from "components/navbar";
import { Link } from "react-router-dom";

export function AppConfiguration() {
  return (
    <div className="flex flex-col bg-[#1c295d]">
      <Navbar />
      <div className="flex flex-col bg-[#fbb930] items-center pt-4 h-[calc(100vh-56px)] w-screen text-black">
        <div className="max-w-6xl w-[80%] min-w-[300px] flex flex-col bg-white/70 rounded-lg p-4 gap-2">
          <div className="flex items-center mb-4">
            <h1 className="text-2xl font-bold text-mompox-blue">
              Configuración
            </h1>
          </div>
          <div className="flex gap-2 items-center">
            <Link to="/admin/variables">
              <button>
                <div className="flex gap-2">
                  <div className="text-lg font-semibold px-4 hover:bg-[#c6c6c6] rounded-lg">
                    Variables
                  </div>
                </div>
              </button>
            </Link>
            <Link to="/admin/icas">
              <button>
                <div className="flex gap-2">
                  <div className="text-lg font-semibold px-4 hover:bg-[#c6c6c6] rounded-lg">
                    ICA
                  </div>
                </div>
              </button>
            </Link>
            <Link to="/admin/wifi-report">
              <button>
                <div className="flex gap-2">
                  <div className="text-lg font-semibold px-4 hover:bg-[#c6c6c6] rounded-lg">
                    Reporte Zonas Wifi
                  </div>
                </div>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
