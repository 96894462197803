import { ApiResponse } from "@models/api-response";

export async function uploadFile(
  file: File
): Promise<ApiResponse<{ fileName: string }>> {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/v1/files`,
      {
        method: "POST",
        body: formData,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        }
      }
    );

    if (!response.ok) {
      return [null, false];
    }

    return [await response.json(), true];
  } catch (error) {
    return [null, false];
  }
}
