import { uploadFile } from "api/file/upload";
import { useRef } from "react";
import { toast } from "react-toastify";

interface UploadFileProps {
  imageUrl?: string;
  onFileUploaded: (newFileName: string) => void;
}

export default function UploadFile({ onFileUploaded, imageUrl }: UploadFileProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  function handleFileUpload(file: File) {
    uploadFile(file).then(([result, ok]) => {
      if (ok) {
        console.log(result);
        onFileUploaded(result.fileName);

        toast.success("Archivo subido correctamente");
        return;
      }

      toast.error(
        "Error al subir archivo, verifique el tamaño (máximo 1mb) y formato"
      );
    });
  }

  return (
    <div className="flex gap-2 items-center">
      <input
        type="file"
        id="import-sensors-file"
        accept=".jpg,.jpeg,.png"
        style={{ display: "none" }}
        onChange={(e) => {
          if (!e.target.files) {
            return;
          }

          e.preventDefault();

          handleFileUpload(e.target.files[0]);
        }}
        ref={fileInputRef}
      />
      <button
        className="outline outline-2 outline-[#77a1ec] text-[#77a1ec] p-1 rounded-lg font-bold"
        onClick={(e) => {
          e.preventDefault();
          fileInputRef.current?.click();
        }}
      >
        Subir archivo
      </button>

      {imageUrl && (
        <img
          src={`${process.env.REACT_APP_API_BASE_URL}/v1/files/${imageUrl}`}
          alt="Imagen del sensor"
          className="w-[50px] h-[50px] rounded-lg mx-auto object-cover"
        />
      )}
    </div>
  );
}
