import { TRAFFIC_PROFILES } from "constants/traffic-profiles";
import { DropdownOption } from "models/dropdown-option.model";
import { updateSensor } from "api/sensor/update-sensor";
import { MonitoringConfig, Sensor } from "models/sensor.model";
import { FormEvent, useState } from "react";
import Select from "react-select";

export default function MonitoringSensorForm({
  onSensorUpdated,
  sensor,
}: {
  onSensorUpdated: () => void;
  sensor: Sensor;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isMonitoringEnabled, setIsMonitoringEnabled] = useState(
    sensor.isMonitoringEnabled ?? false
  );
  const [monitoringConfig, setMonitoringConfig] = useState<MonitoringConfig>({
    ...(sensor.monitoringConfig ?? {
      host: "",
      monitoringInterval: "10m",
      enableTrafficMonitoring: false,
      trafficMonitoringProfile: "microtik",
    }),
  });
  const availableTrafficProfiles: DropdownOption[] = TRAFFIC_PROFILES.map(
    (profile) => ({
      label: profile.title,
      value: profile.name,
    })
  );

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);

    const data = {
      isMonitoringEnabled,
      monitoringConfig,
    };
    // Update the sensor
    const response = await updateSensor(sensor._id, data);

    if (response.ok) {
      onSensorUpdated();
      setIsMonitoringEnabled(false);
      setMonitoringConfig({
        host: "",
        monitoringInterval: "10m",
        enableTrafficMonitoring: false,
        trafficMonitoringProfile: "microtik",
      });
    } else {
      console.error("Failed to update sensor", sensor);
    }

    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-2 text-black">
      <h2 className="text-2xl text-mompox-blue font-bold">
        Monitoreo de sensor
      </h2>
      <div className="max-h-[600px] overflow-y-auto">
        <div>
          <label className="block mb-1">
            <input
              type="checkbox"
              checked={isMonitoringEnabled}
              onChange={(e) => setIsMonitoringEnabled(e.target.checked)}
              className="mr-2"
            />
            Activar monitoreo
          </label>
        </div>
        {isMonitoringEnabled && (
          <div>
            <div className="mb-2">
              <label className="block mb-1">Host</label>
              <input
                type="text"
                value={monitoringConfig.host}
                onChange={(e) =>
                  setMonitoringConfig({
                    ...monitoringConfig,
                    host: e.target.value,
                  })
                }
                className="p-2 rounded-lg border"
              />
            </div>
            {/* TODO: Implement interval input */}
            {/* <div className="mb-2">
              <label className="block mb-1">Intervalo de monitorizacion</label>
              <input
                type="text"
                value={monitoringConfig.monitoringInterval}
                onChange={(e) =>
                  setMonitoringConfig({
                    ...monitoringConfig,
                    monitoringInterval: e.target.value,
                  })
                }
                className="p-2 rounded-lg border"
              />
            </div> */}
            <div className="mb-2">
              <label className="block mb-1">
                <input
                  type="checkbox"
                  checked={monitoringConfig.enableTrafficMonitoring}
                  onChange={(e) =>
                    setMonitoringConfig({
                      ...monitoringConfig,
                      enableTrafficMonitoring: e.target.checked,
                    })
                  }
                  className="mr-2"
                />
                Activar monitoreo de tráfico
              </label>
            </div>
            <div className="mb-2">
              <label className="block mb-1">Perfil de tráfico:</label>

              <Select
                options={availableTrafficProfiles}
                value={availableTrafficProfiles.find(
                  (option) =>
                    option.value === monitoringConfig.trafficMonitoringProfile
                )}
                onChange={(selected) =>
                  setMonitoringConfig({
                    ...monitoringConfig,
                    trafficMonitoringProfile: selected!.value,
                  })
                }
                placeholder="Perfil de dispositivo tráfico"
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
          </div>
        )}
      </div>
      {isLoading ? (
        <div className="flex flex-col items-center justify-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <button
          type="submit"
          className={
            isLoading
              ? "bg-gray-300 text-gray-500 p-2 rounded-lg"
              : "mompox-blue text-white p-2 rounded-lg"
          }
        >
          {isLoading ? "Guardando..." : "Guardar"}
        </button>
      )}
    </form>
  );
}
