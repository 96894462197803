import { AirQualitySensorPopup } from "components/forms/sensor/air-quality-sensor-popup";
import { CCTVSensorPopup } from "components/forms/sensor/cctv-sensor-popup";
import { SensorPopup, TableSensorPopup } from "components/forms/sensor/sensor-popup";
import { WaterQualitySensorPopup } from "components/forms/sensor/water-quality-sensor-popup";
import WifiPopup from "components/forms/sensor/wifi-sensor-popup";

export const SENSOR_TYPES = [
  {
    id: "hotel",
    name: "Hotel",
    icon: "icons/iconos plataforma mompox escritorio-21.svg",
    markerIcon: "icons/iconos plataforma mompox escritorio-40.svg",
    offlineMarkerIcon: "icons/iconos plataforma mompox escritorio-40.svg",
    popup: SensorPopup,
    reportsEnabled: false,
    adminOnly: false,
  },
  {
    id: "restaurant",
    name: "Restaurante",
    icon: "icons/restaurant.svg",
    markerIcon: "icons/iconos plataforma mompox escritorio-41.svg",
    offlineMarkerIcon: "icons/iconos plataforma mompox escritorio-41.svg",
    popup: SensorPopup,
    reportsEnabled: false,
    adminOnly: false,
  },
  {
    id: "smart-point",
    name: "Punto Smart",
    icon: "icons/star.svg",
    markerIcon: "icons/iconos plataforma mompox escritorio-42.svg",
    offlineMarkerIcon: "icons/iconos plataforma mompox escritorio-42.svg",
    popup: SensorPopup,
    reportsEnabled: false,
    adminOnly: false,
  },
  {
    id: "smart-mural",
    name: "Mural Inteligente",
    icon: "icons/lightbulb.svg",
    markerIcon: "icons/iconos plataforma mompox escritorio-38.svg",
    offlineMarkerIcon: "icons/iconos plataforma mompox escritorio-39.svg",
    popup: SensorPopup,
    reportsEnabled: false,
    adminOnly: false,
  },
  {
    id: "water-quality",
    name: "Calidad del Agua",
    icon: "drop.svg",
    markerIcon: "icons/iconos plataforma mompox escritorio-30.svg",
    offlineMarkerIcon: "icons/iconos plataforma mompox escritorio-30.svg",
    popup: WaterQualitySensorPopup,
    reportsEnabled: true,
    adminOnly: false,
  },
  {
    id: "air-quality",
    name: "Calidad del Aire",
    icon: "icons/iconos plataforma mompox escritorio-01.svg",
    markerIcon: "icons/iconos plataforma mompox escritorio-35.svg",
    offlineMarkerIcon: "icons/iconos plataforma mompox escritorio-36.svg",
    popup: AirQualitySensorPopup,
    reportsEnabled: true,
    adminOnly: false,
  },
  {
    id: "wifi-zone",
    name: "Zona Wifi",
    icon: "wifi.svg",
    markerIcon: "icons/iconos plataforma mompox escritorio-33.svg",
    offlineMarkerIcon: "icons/iconos plataforma mompox escritorio-32.svg",
    popup: WifiPopup,
    reportsEnabled: false,
    adminOnly: false,
  },
  {
    id: "water-level",
    name: "Nivel de Agua",
    icon: "water-level.svg",
    markerIcon: "icons/iconos plataforma mompox escritorio-44.svg",
    offlineMarkerIcon: "icons/iconos plataforma mompox escritorio-44.svg",
    popup: TableSensorPopup,
    reportsEnabled: true,
    adminOnly: false,
  },
  {
    id: "drone",
    name: "Dron",
    icon: "drone.svg",
    markerIcon: "icons/iconos plataforma mompox escritorio-45.svg",
    offlineMarkerIcon: "icons/iconos plataforma mompox escritorio-45.svg",
    popup: TableSensorPopup,
    reportsEnabled: false,
    adminOnly: false,
  },
  {
    id: "cctv",
    name: "Cámara",
    icon: "icons/iconos plataforma mompox escritorio-46.svg",
    markerIcon: "icons/iconos plataforma mompox escritorio-50.png",
    offlineMarkerIcon: "icons/iconos plataforma mompox escritorio-49.png",
    popup: CCTVSensorPopup,
    reportsEnabled: false,
    adminOnly: true,
  },
];

export const SENSOR_TYPE_POPUP_MAP = new Map(SENSOR_TYPES.map((type) => [type.id, type.popup]));
export const SENSOR_TYPE_MARKER_ICON_MAP = new Map(SENSOR_TYPES.map((type) => [type.id, type.markerIcon]));
export const SENSOR_TYPE_OFFLINE_MARKER_ICON_MAP = new Map(SENSOR_TYPES.map((type) => [type.id, type.offlineMarkerIcon]));
export const SENSOR_TYPE_NAME_MAP = new Map(SENSOR_TYPES.map((type) => [type.name, type]));