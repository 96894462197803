import Select from "react-select";
import { FormEvent, useState } from "react";
import { Sensor } from "models/sensor.model";
import { DropdownOption } from "models/dropdown-option.model";
import { updateSensor } from "api/sensor/update-sensor";
import { SENSOR_TYPES } from "constants/sensor-types";
import { Instructions } from "./sensor-instructions";
import UploadFile from "components/upload-file";
import { useAuth } from "hooks/use-auth";

export default function EditSensorForm({
  onSensorUpdated,
  sensor,
  variables,
}: {
  onSensorUpdated: () => void;
  sensor: Sensor;
  variables: DropdownOption[];
}) {
  const [name, setName] = useState(sensor.name ?? "");
  const [description, setDescription] = useState(sensor.description ?? "");
  const [imageUrl, setImageUrl] = useState(sensor.imageUrl ?? "");
  const [selectedVariables, setSelectedVariables] = useState<DropdownOption[]>(
    sensor.variables
      ? sensor.variables.split(",").map((v) => ({ label: v, value: v }))
      : []
  );
  const [sensorType, setSensorType] = useState(
    sensor.type ?? SENSOR_TYPES[0].id
  );
  const [latitude, setLatitude] = useState(`${sensor.latitude ?? ""}`);
  const [longitude, setLongitude] = useState(`${sensor.longitude ?? ""}`);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [rtspUrl, setRtspUrl] = useState(sensor.rtspUrl ?? "");
  const [rtspUrl2, setRtspUrl2] = useState(sensor.rtspUrl2 ?? "");
  const [rtspUrl3, setRtspUrl3] = useState(sensor.rtspUrl3 ?? "");
  const [rtspUrl4, setRtspUrl4] = useState(sensor.rtspUrl4 ?? "");

  const { isAdmin } = useAuth();

  const availableVariables: DropdownOption[] = variables;
  const sensorTypes: DropdownOption[] = SENSOR_TYPES.map((type) => ({
    label: type.name,
    value: type.id,
  }));

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!name || !latitude || !longitude) {
      setError("Por favor complete todos los campos.");
      return;
    }

    setLoading(true);

    const response = await updateSensor(sensor._id, {
      name,
      variables: selectedVariables.map((v) => v.value).join(","),
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      type: sensorType,
      description,
      imageUrl,
      rtspUrl,
      rtspUrl2,
      rtspUrl3,
      rtspUrl4,
    });

    if (response.ok) {
      onSensorUpdated();
      setName("");
      setSelectedVariables([]);
      setLatitude("");
      setLongitude("");
      setDescription("");
      setImageUrl("");
      setError(null);
    } else {
      setError("Hubo un error al editar el sensor, intente de nuevo.");
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-2 text-black">
      <h2 className="text-2xl text-mompox-blue font-bold">Editar sensor</h2>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Name"
        required
        className="p-2 rounded-lg border"
      />
      <textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Description"
        className="p-2 rounded-lg border"
      />
      <Select
        options={availableVariables}
        isMulti
        value={selectedVariables}
        onChange={(selected) =>
          setSelectedVariables(selected as DropdownOption[])
        }
        placeholder="Variables"
        className="basic-multi-select"
        classNamePrefix="select"
      />
      <Select
        options={sensorTypes}
        value={sensorTypes.find((option) => option.value === sensorType)}
        onChange={(selected) => setSensorType(selected!.value)}
        placeholder="Tipo de sensor"
        className="basic-multi-select"
        classNamePrefix="select"
      />
      <input
        type="number"
        value={latitude}
        onChange={(e) => setLatitude(e.target.value)}
        placeholder="Latitude"
        required
        className="p-2 rounded-lg border"
      />
      <input
        type="number"
        value={longitude}
        onChange={(e) => setLongitude(e.target.value)}
        placeholder="Longitude"
        required
        className="p-2 rounded-lg border"
      />

      <UploadFile
        imageUrl={imageUrl}
        onFileUploaded={(newFileName) => setImageUrl(newFileName)}
      />

      {isAdmin && sensorType === "cctv" && (
        <div className="flex flex-col gap-2">
          <div className="text-lg font-semibold">Configuración de cámara</div>

          <div className="flex flex-col gap-1">
            <div>URL RTSP 1</div>
            <input
              value={rtspUrl}
              onChange={(e) => setRtspUrl(e.target.value)}
              placeholder="URL RSTP 1"
              className="p-2 rounded-lg border"
            />
          </div>

          <div className="flex flex-col gap-1">
            <div>URL RTSP 2</div>
            <input
              value={rtspUrl2}
              onChange={(e) => setRtspUrl2(e.target.value)}
              placeholder="URL RSTP 2"
              className="p-2 rounded-lg border"
            />
          </div>

          <div className="flex flex-col gap-1">
            <div>URL RTSP 3</div>
            <input
              value={rtspUrl3}
              onChange={(e) => setRtspUrl3(e.target.value)}
              placeholder="URL RSTP 3"
              className="p-2 rounded-lg border"
            />
          </div>

          <div className="flex flex-col gap-1">
            <div>URL RTSP 4</div>
            <input
              value={rtspUrl4}
              onChange={(e) => setRtspUrl4(e.target.value)}
              placeholder="URL RSTP 4"
              className="p-2 rounded-lg border"
            />
          </div>
        </div>
      )}

      <button
        disabled={loading}
        type="submit"
        className={
          loading
            ? "bg-gray-300 text-gray-500 p-2 rounded-lg"
            : "mompox-blue text-white p-2 rounded-lg"
        }
      >
        Guardar
      </button>
      {error && <p className="text-red-500">{error}</p>}
      <Instructions />
    </form>
  );
}
