import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "hooks/use-auth";
import { ROUTES_WITH_TITLES } from "constants/route-titles";

const Navbar = () => {
  const { logout, isLoggedIn, isAdmin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation(); // <-- location object from react-router

  // State to toggle the dropdown
  const [menuOpen, setMenuOpen] = useState(false);

  // Ref to detect clicks outside the menu
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  // Find a matching route title
  let title = "";
  for (const route of ROUTES_WITH_TITLES) {
    if (route.exact && route.path === location.pathname) {
      // exact match
      title = route.title;
      break;
    } else if (
      !route.exact &&
      location.pathname.startsWith(route.path) &&
      route.path !== "/"
    ) {
      // e.g. partial match for something like "/profile/1"
      title = route.title;
      break;
    }
    // If none match, title stays empty
  }

  return (
    <div className="h-14 flex items-center gap-2 px-4 mompox-blue">
      {/* Logo on the left */}
      <Link className="flex items-center" to="/">
        <img aria-hidden src="/mompox.svg" alt="App logo" width={96} />
      </Link>

      {/* Title in the center */}
      <div className="mx-auto text-lg font-bold text-white">{title}</div>

      {/* 3-Dot “kebab” menu on the right */}
      <div className="relative" ref={menuRef}>
        <button
          onClick={() => setMenuOpen((prev) => !prev)}
          className="p-2 hover:bg-white hover:bg-opacity-10 rounded-full"
          aria-label="Menu"
        >
          <svg
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
            className="text-white"
          >
            <circle cx="12" cy="5" r="2" />
            <circle cx="12" cy="12" r="2" />
            <circle cx="12" cy="19" r="2" />
          </svg>
        </button>

        {menuOpen && (
          <div className="absolute right-0 mt-2 w-40 rounded-md bg-white shadow-md text-black z-50">
            <ul className="py-1">
              {isLoggedIn ? (
                <>
                  {isAdmin && (
                    <li>
                      <Link
                        to="/admin/configuration"
                        className="block px-4 py-2 hover:bg-gray-100"
                        onClick={() => setMenuOpen(false)}
                      >
                        Configuración
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link
                      to="/report"
                      className="block px-4 py-2 hover:bg-gray-100"
                      onClick={() => setMenuOpen(false)}
                    >
                      Reporte
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/my-sensors"
                      className="block px-4 py-2 hover:bg-gray-100"
                      onClick={() => setMenuOpen(false)}
                    >
                      Mis sensores
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/profile"
                      className="block px-4 py-2 hover:bg-gray-100"
                      onClick={() => setMenuOpen(false)}
                    >
                      Perfil
                    </Link>
                  </li>
                  <li>
                    <button
                      onClick={handleLogout}
                      className="w-full text-left px-4 py-2 hover:bg-gray-100"
                    >
                      Cerrar sesión
                    </button>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link
                      to="/report"
                      className="block px-4 py-2 hover:bg-gray-100"
                      onClick={() => setMenuOpen(false)}
                    >
                      Reporte
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/login"
                      className="block px-4 py-2 hover:bg-gray-100"
                      onClick={() => setMenuOpen(false)}
                    >
                      Ingresar
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
