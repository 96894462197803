import { getSensors } from "api/sensor/get-sensors";
import { Sensor } from "models/sensor.model";
import Navbar from "components/navbar";
import React, { useEffect, useMemo, useState } from "react";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import { DropdownOption } from "models/dropdown-option.model";
import Select from "react-select";
import { getMonitoringData } from "api/monitoring-data/get-monitoring-data";
import { MonitoringDataDto } from "api/monitoring-data/dto/monitoring-data.dto";
import { EChartsOption } from "echarts-for-react";
import ReactECharts from "echarts-for-react";
import { TrafficChart } from "components/traffic-chart";
import { useLocation } from "react-router-dom";

export default function MonitoringReport() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const sensorId: string | null = params.get("sensorId");
  const [startDate, setStartDate] = useState(subDays(new Date(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [selectedSensorId, setSelectedSensorId] = useState<string | null>(
    sensorId
  );
  const [availableSensorOptions, setAvailableSensorOptions] = useState<
    DropdownOption[]
  >([]);
  const [availableSensors, setAvailableSensors] = useState<Sensor[]>([]);
  const selectedSensor = useMemo(() => {
    if (!selectedSensorId || !availableSensorOptions.length) {
      return null;
    }

    return availableSensors.find((sensor) => sensor._id === selectedSensorId);
  }, [selectedSensorId, availableSensorOptions]);

  useEffect(() => {
    fetchSensors();
  }, []);

  async function fetchSensors() {
    const response = await getSensors();

    if (!response.ok) {
      return;
    }

    const sensors: Sensor[] = await response.json();
    setAvailableSensorOptions(
      sensors
        .filter((sensor) => sensor.isMonitoringEnabled)
        .map((sensor) => ({ label: sensor.name, value: sensor._id }))
    );
    setAvailableSensors(sensors);
  }

  return (
    <div className="flex flex-col bg-[#1c295d]">
      <Navbar />

      {availableSensorOptions.length > 0 && (
        <div
          style={{
            background: " linear-gradient(90deg, #f9d110 35%, #f8ab0f 100%)",
          }}
          className="flex flex-col items-center py-16 h-[calc(100vh-56px)] w-screen text-black flex-1 overflow-y-auto"
        >
          <div className="max-w-6xl w-[80%] min-w-[300px] min-h-[900px] flex flex-col bg-white/70 gap-2 rounded-lg overflow-hidden p-4">
            <div className="flex items-center mb-4">
              <h1 className="text-2xl font-bold text-mompox-blue">Monitoreo</h1>
            </div>

            <div>
              <label className="block mb-1">Fecha inicio</label>
              <input
                type="date"
                value={format(startDate, "yyyy-MM-dd")}
                onChange={(e) => setStartDate(new Date(e.target.value))}
                className="border p-2 w-full rounded-lg shadow"
              />
            </div>
            <div>
              <label className="block mb-1">Fecha fin</label>
              <input
                type="date"
                value={format(endDate, "yyyy-MM-dd")}
                onChange={(e) => setEndDate(new Date(e.target.value))}
                className="border p-2 w-full rounded-lg shadow"
              />
            </div>
            <div>
              <label className="block mb-1">Sensor</label>
              <Select
                options={availableSensorOptions}
                value={availableSensorOptions.find(
                  (option) => option.value === selectedSensorId
                )}
                onChange={(selected) => setSelectedSensorId(selected!.value)}
                placeholder="Sensor"
                className="basic-multi-select shadow rounded-lg border-0"
                classNamePrefix="select"
              />
            </div>

            {selectedSensorId && selectedSensor && (
              <div className="flex flex-col gap-4">
                <PingChart
                  sensor={selectedSensor}
                  startDate={startDate}
                  endDate={endDate}
                />

                <TrafficChart
                  sensor={selectedSensor}
                  startDate={startDate}
                  endDate={endDate}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

interface PingChartProps {
  sensor: Sensor;
  startDate: Date;
  endDate: Date;
}

function PingChart({ sensor, startDate, endDate }: PingChartProps) {
  const [chart, setChart] = useState<EChartsOption | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getMonitoringData(
      sensor._id,
      startOfDay(startDate).toISOString(),
      endOfDay(endDate).toISOString(),
      "ping"
    )
      .then((response) => {
        if (!response.ok) {
          return;
        }

        return response.json();
      })
      .then((data) => {
        // Build the series array. Each sensor is a separate line.
        const seriesData = {
          name: sensor.name,
          type: "line",
          showSymbol: false,
          data: data.map((datum: MonitoringDataDto) => [
            format(new Date(datum.date), "yyyy-MM-dd HH:mm"),
            datum.latency > 0 ? datum.latency : "Offline",
          ]),
        };

        const option = {
          title: {
            text: "Ping (ms)",
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: sensor.name,
          },
          xAxis: {
            type: "time",
            // If you prefer discrete categories, you can transform the date
            // to a formatted string and use type: 'category'
          },
          yAxis: {
            type: "value",
          },
          series: seriesData,
        };

        setChart(option);
      })
      .catch((error) => {
        console.error("Error al obtener datos de monitorización:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [sensor._id, startDate, endDate, sensor.name]);

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (!chart) {
    return <div>No hay datos disponibles</div>;
  }

  return (
    <ReactECharts option={chart} style={{ height: "400px", width: "100%" }} />
  );
}
