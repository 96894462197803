import { ApiResponse } from "@models/api-response";
import { CreateSensorDto } from "./dto/create-sensor.dto";

export function createSensor(newSensor: CreateSensorDto) {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/sensors`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newSensor),
  });
}

export async function createSensorMultiple(
  newSensors: CreateSensorDto[]
): Promise<ApiResponse<null>> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/v1/sensors/mass-import`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newSensors),
      }
    );

    if (!response.ok) {
      return [null, false];
    }

    return [null, true];
  } catch (error) {
    return [null, false];
  }
}
