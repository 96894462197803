import React, { useState, useEffect } from "react";
import { SENSOR_TYPES } from "constants/sensor-types";
import { DropdownOption } from "models/dropdown-option.model";
import { getVariables } from "api/variables/get-variables";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import { getSensorData } from "api/sensor/get-multi-sensor-data.dto";
import ReactECharts from "echarts-for-react";
import Select from "react-select";
import { Sensor } from "models/sensor.model";

// Build a static array of sensor types that support "reportsEnabled"
const REPORT_SENSOR_TYPES: DropdownOption[] = SENSOR_TYPES.filter(
  (type) => type.reportsEnabled
).map((type) => ({
  label: type.name,
  value: type.id,
}));

type ReportFormProps = {
  /** Optional: if provided, we hide the multi-sensor UI
   *  and just generate charts for the single sensor.
   */
  sensor?: Sensor;
};

const ReportForm: React.FC<ReportFormProps> = ({ sensor }) => {
  const [startDate, setStartDate] = useState(subDays(new Date(), 1));
  const [endDate, setEndDate] = useState(new Date());

  // Multi-sensor states (used if `sensor` is NOT provided)
  const [sensorType, setSensorType] = useState<string>(
    REPORT_SENSOR_TYPES[0].value
  );
  const [selectedSensors, setSelectedSensors] = useState<DropdownOption[]>([]);
  const [availableSensors, setAvailableSensors] = useState<any[]>([]);
  const [filteredSensors, setFilteredSensors] = useState<DropdownOption[]>([]);

  // Variable states
  const [availableVariables, setAvailableVariables] = useState<
    DropdownOption[]
  >([]);
  const [filteredVariables, setFilteredVariables] = useState<DropdownOption[]>(
    []
  );
  const [selectedVariables, setSelectedVariables] = useState<DropdownOption[]>(
    []
  );

  // Chart data
  const [sensorData, setSensorData] = useState<any[] | null>(null);

  /**
   * INITIAL LOAD: fetch all sensors (if needed) + fetch variable definitions
   */
  useEffect(() => {
    if (!sensor) {
      fetchAvailableSensors();
    }
    fetchVariables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * When sensorType changes, filter the sensors for that type
   * (only if we do NOT have a single `sensor` passed in).
   */
  useEffect(() => {
    if (sensor || !sensorType) return; // skip if single sensor

    setFilteredSensors(
      availableSensors
        .filter((s) => s.type === sensorType)
        .map((s) => ({ label: s.name, value: s._id }))
    );

    // Reset these whenever sensorType changes
    setSensorData(null);
    setSelectedSensors([]);
    setSelectedVariables([]);
  }, [sensorType, availableSensors, sensor]);

  /**
   * Whenever the user picks new sensors, compute the "common variables"
   * across them, so that the user can only pick variables
   * that all selected sensors share.
   */
  useEffect(() => {
    if (sensor) return; // skip if single sensor

    if (!selectedSensors.length) {
      setFilteredVariables([]);
      return;
    }

    // 1. Find each actual sensor in the DB
    const actualSensors = selectedSensors.map((s) =>
      availableSensors.find((as) => as._id === s.value)
    );

    // 2. Get the variables that ALL these sensors share
    const commonVars = actualSensors.reduce((acc, sensorItem) => {
      if (!sensorItem) return acc;
      const sensorVars = sensorItem.variables.split(",");
      // first sensor => set initial
      if (!acc.length) return sensorVars;
      // otherwise, keep only intersection
      return acc.filter((variable: string) => sensorVars.includes(variable));
    }, [] as string[]);

    // 3. Filter the "availableVariables" to keep only those in `commonVars`
    const newFilteredVars = availableVariables.filter((av) =>
      commonVars.includes(av.value)
    );
    setFilteredVariables(newFilteredVars);

    // Reset chart data whenever the selection changes
    setSensorData(null);
  }, [
    selectedSensors,
    availableSensors,
    sensorType,
    sensor,
    availableVariables,
  ]);

  /**
   * For single-sensor mode (if `sensor` is passed):
   * - We skip the multi-sensor UI
   * - We automatically pick all variables from that sensor
   *   (and map them to our availableVariables to get nice labels).
   */
  useEffect(() => {
    if (!sensor || !availableVariables.length) return;

    // Example: sensor.variables might be "temperature,ph" (comma separated)
    const sensorVarNames = sensor.variables?.split(",") || [];
    // Map them to the shape {label, value} using `availableVariables`
    const matchedVars = availableVariables.filter((av) =>
      sensorVarNames.includes(av.value)
    );

    setSelectedSensors([{ label: sensor.name, value: sensor._id }]);
    setFilteredVariables(matchedVars);
    setSelectedVariables(matchedVars);
  }, [sensor, availableVariables]);

  /**
   * Both single-sensor and multi-sensor modes:
   * whenever sensors & variables are chosen, fetch data for them
   */
  useEffect(() => {
    if (!selectedSensors.length || !selectedVariables.length) {
      setSensorData(null);
      return;
    }

    fetchSensorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSensors, selectedVariables, startDate, endDate]);

  /**
   * FETCHES
   */
  const fetchAvailableSensors = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/v1/sensors`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setAvailableSensors(data);
      } else {
        console.error("Error al obtener los sensores");
      }
    } catch (error) {
      console.error("Error al obtener los sensores:", error);
    }
  };

  const fetchVariables = async () => {
    const [variables, ok] = await getVariables();
    if (!ok) {
      return;
    }
    // Transform to { label, value }
    const newVars = variables.map((v: any) => ({
      label: v.title,
      value: v.name,
    }));
    setAvailableVariables(newVars);
  };

  const fetchSensorData = async () => {
    let allData: any[] = [];
    try {
      for (const sensorOption of selectedSensors) {
        // Start & end are always the same for all sensors
        const startIso = startOfDay(startDate).toISOString();
        const endIso = endOfDay(endDate).toISOString();

        const dataResp = await getSensorData(
          sensorOption.value,
          startIso,
          endIso
        );
        if (!dataResp.ok) {
          continue;
        }
        const sensorPoints = await dataResp.json();
        allData = allData.concat(sensorPoints);
      }
      setSensorData(allData);
    } catch (error) {
      console.error("Error fetching sensor data:", error);
    }
  };

  /**
   * RENDER
   */
  return (
    <div className="w-full text-black flex flex-col gap-4">
      {/* Date Pickers */}
      <div>
        <label className="block mb-1">Fecha inicio</label>
        <input
          type="date"
          value={format(startDate, "yyyy-MM-dd")}
          onChange={(e) => setStartDate(new Date(e.target.value))}
          className="border p-2 w-full"
        />
      </div>
      <div>
        <label className="block mb-1">Fecha fin</label>
        <input
          type="date"
          value={format(endDate, "yyyy-MM-dd")}
          onChange={(e) => setEndDate(new Date(e.target.value))}
          className="border p-2 w-full"
        />
      </div>

      {/* If NO single sensor is passed, show sensor-type and sensor selections */}
      {!sensor && (
        <>
          <div>
            <label className="block mb-1">Tipo de sensor</label>
            <Select
              options={REPORT_SENSOR_TYPES}
              value={REPORT_SENSOR_TYPES.find(
                (option) => option.value === sensorType
              )}
              onChange={(selected) => setSensorType(selected!.value)}
              placeholder="Tipo de sensor"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div>
            <label className="block mb-1">Sensores</label>
            <Select
              options={filteredSensors}
              isMulti
              value={selectedSensors}
              onChange={(selected) =>
                setSelectedSensors(selected as DropdownOption[])
              }
              placeholder="Sensores"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div>
            <label className="block mb-1">Variables</label>
            <Select
              options={filteredVariables}
              isMulti
              value={selectedVariables}
              onChange={(selected) =>
                setSelectedVariables(selected as DropdownOption[])
              }
              placeholder="Variables"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </>
      )}

      {/* If single sensor is passed, 
          we skip sensor-type + sensor selection
          and automatically pick all sensor variables.
       */}

      {/* Render Charts */}
      {selectedSensors.length > 0 &&
        selectedVariables.length > 0 &&
        (sensorData ? (
          <div className="mt-4">
            {selectedVariables.map((variable) => {
              // Build the series array. Each sensor is a separate line
              const seriesData = selectedSensors.map((sensorOpt) => {
                // Filter out the sensorData for this sensor
                const dataPoints = sensorData.filter(
                  (d) => d.sensor?.value === sensorOpt.value
                );
                // Convert data points to [time, value]
                const lineData = dataPoints.map((dp) => {
                  const numericValue = dp[variable.value]?.value || 0;
                  const dateString = dp.date?.value;
                  return [dateString, numericValue];
                });

                return {
                  name: sensorOpt.label,
                  type: "line",
                  data: lineData,
                  showSymbol: false,
                };
              });

              const option = {
                title: { text: variable.label },
                tooltip: { trigger: "axis" },
                legend: {
                  data: selectedSensors.map((s) => s.label),
                },
                xAxis: {
                  type: "time",
                },
                yAxis: {
                  type: "value",
                },
                series: seriesData,
              };

              return (
                <div key={variable.value} style={{ marginBottom: "2rem" }}>
                  <ReactECharts
                    option={option}
                    style={{ height: "400px", width: "100%" }}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <p className="mt-4">No hay datos disponibles</p>
        ))}
    </div>
  );
};

export default ReportForm;
