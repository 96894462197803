import ReportForm from "components/forms/report-form";
import Navbar from "components/navbar";;

export default function Report() {
  return (
    <div className="flex flex-col bg-[#1c295d] min-h-screen">
      <Navbar />
      {/* You can wrap it with any styling you want */}
      <div
        style={{
          background: "linear-gradient(90deg, #f9d110 35%, #f8ab0f 100%)",
        }}
        className="flex justify-center py-16 px-4"
      >
        <div className="w-full max-w-6xl bg-white/70 p-4 rounded-lg min-h-[900px] overflow-auto">
          <h1 className="text-2xl font-bold text-mompox-blue mb-4">Reporte</h1>
          <ReportForm />
        </div>
      </div>
    </div>
  );
}
