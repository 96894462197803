import { ApiResponse } from "@models/api-response";
import { CreateWifiZoneReportDataDto } from "./dto/create-wifi-zone-report-data.dto";
import { WifiZoneReportDataDto } from "./dto/wifi-zone-report-data.dto";

export async function createWifiZoneReportData(
  data: CreateWifiZoneReportDataDto[],
  clear = false
): Promise<ApiResponse<WifiZoneReportDataDto[]>> {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/v1/wifi-zone-report-data?clear=${clear}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify(data),
    }
  );

  if (!response.ok) {
    return [null, false];
  }

  return [await response.json(), true];
}
